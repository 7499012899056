import {
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridOffset,
  OwnUpGridWrapper,
  OwnUpIcon,
  ownUpLightTheme,
  OwnUpSmallSubheadlineMedium,
  OwnUpThemeProvider
} from '@rategravity/own-up-component-library';
import React from 'react';
import { getRequiredFields, serializeToImageObject } from '../../modules/handle-sanity-data';
import { Body, Button, ButtonWrapper, Headline, Section } from './elements';

export const IconSingle = ({ data }: { data: Record<string, any>[] }) => {
  const types: string[] = ['mainImage', 'heading', 'callToAction', 'tagline'];
  const content = data ? getRequiredFields(data, types) : {};
  const { headingObj, taglineObj, callToActionObj, mainImageObj } = content;

  return (
    <Section aria-label="Job Openings">
      <OwnUpThemeProvider theme={ownUpLightTheme}>
        <OwnUpGridWrapper>
          <OwnUpGridContainer variant="legacy">
            <OwnUpGridOffset xs={0} sm />
            <OwnUpGridItem xs={12} sm={10} lg={8} xl={6}>
              {mainImageObj && (
                <OwnUpIcon
                  icon={() => serializeToImageObject(mainImageObj, {})}
                  style={{ justifyContent: 'center' }}
                  height="64px"
                />
              )}
              {headingObj?.heading && (
                <Headline>
                  <OwnUpSmallSubheadlineMedium variant={'h2'}>
                    {headingObj.heading}
                  </OwnUpSmallSubheadlineMedium>
                </Headline>
              )}
              {taglineObj?.tagline && <Body>{taglineObj.tagline}</Body>}
              {callToActionObj?.linkText && (
                <ButtonWrapper>
                  <Button text={callToActionObj.linkText} link={callToActionObj?.url || '#'} />
                </ButtonWrapper>
              )}
            </OwnUpGridItem>
            <OwnUpGridOffset xs={0} sm />
          </OwnUpGridContainer>
        </OwnUpGridWrapper>
      </OwnUpThemeProvider>
    </Section>
  );
};
