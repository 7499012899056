import { WHITE_100 } from '@rategravity/own-up-component-library';
import React from 'react';
import { getRequiredFields, serializeToImageObject } from '../../../modules/handle-sanity-data';
import { PureImageMultiple } from '../../image-multiple';

export const formatAboutImageMultipledata = (data: Record<string, any>) => {
  const formattedData = data.pageBuilder.map((element: Record<string, any>) => {
    const requiredTypes = ['heading', 'tagline', 'mainImage', 'callToAction', 'body'];
    const elementContent = getRequiredFields(element.pageBuilder, requiredTypes);
    const { headingObj, taglineObj, bodyObj, callToActionObj, callToActionObj1, mainImageObj } =
      elementContent;
    return {
      image: serializeToImageObject(mainImageObj, { style: { width: '193px' } }),
      data: {
        header: headingObj?.heading,
        italicText: taglineObj?.tagline,
        body: bodyObj?.body,
        social: {
          linkedin: callToActionObj?.url || ''
        },
        articlesLink: callToActionObj1?.url || ''
      }
    };
  });

  return formattedData;
};

export const AboutSection3 = ({ data }: { data: Record<string, any> }) => {
  const types: string[] = ['heading', 'section'];
  const content = data ? getRequiredFields(data, types) : {};
  console.log('content', content);
  const ImageMultipleValueProps = formatAboutImageMultipledata(content?.sectionObj);

  const heading = content?.headingObj?.heading || '';
  return (
    <PureImageMultiple
      highlightText={heading}
      backgroundColor={WHITE_100}
      valueProps={ImageMultipleValueProps}
    />
  );
};
