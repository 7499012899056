import {
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridItemProps,
  OwnUpGridOffset,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import React from 'react';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { OverlineText, OverlineWrapper } from '../overline-text';
import { ImageWithTextProps } from '.';

const StyledMobileTitle = styled(OverlineWrapper)`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    display: none;
  }
  width: 100%;
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4)}px;
`;

export const MobileTitle = ({ children }: PropsWithChildren<{}>) => (
  <StyledMobileTitle>
    <OverlineText>{children}</OverlineText>
  </StyledMobileTitle>
);

const StyledDesktopTitle = styled(OverlineWrapper)`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('sm')} {
    display: none;
  }
  justify-content: inherit;
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2.5)}px;
`;

export const DesktopTitle = ({ children }: PropsWithChildren<{}>) => (
  <StyledDesktopTitle>
    <OverlineText>{children}</OverlineText>
  </StyledDesktopTitle>
);

export const TextGridItem = ({
  children,
  imageOrientation
}: PropsWithChildren<Pick<ImageWithTextProps, 'imageOrientation'>>) => (
  <OwnUpGridContainer variant="legacy">
    <OwnUpGridOffset xs={0} sm md={0} xl={imageOrientation === 'right' ? 2 : 0} />
    <OwnUpGridItem xs={12} sm={10} md={12} xl={10}>
      {children}
    </OwnUpGridItem>
    <OwnUpGridOffset xs={0} sm md={0} xl={imageOrientation === 'right' ? 0 : 2} />
  </OwnUpGridContainer>
);

const ImgWrapper = styled.div`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('sm')} {
    width: 200px;
    height: 200px;
    margin: 0 auto;
  }
`;

export const ImageGridItem = ({ children }: PropsWithChildren<{}>) => (
  <OwnUpGridContainer variant="legacy">
    <OwnUpGridOffset xs />
    <OwnUpGridItem xs={8} sm={6} md={10} xl={8}>
      <ImgWrapper>{children}</ImgWrapper>
    </OwnUpGridItem>
    <OwnUpGridOffset xs />
  </OwnUpGridContainer>
);

export const ContentHalf = ({ children, ...props }: PropsWithChildren<OwnUpGridItemProps>) => (
  <OwnUpGridItem xs={12} md={6} {...props}>
    {children}
  </OwnUpGridItem>
);
