import { OwnUpGridWrapper, PropsWithTheme } from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import { serializeToImageObject } from '../../modules/handle-sanity-data';
import { PortableTextComponent } from '../portable-text';
import {
  formatStoryData,
  OurStoryBlock,
  OurStoryBlockHeadline,
  OurStoryBlockText,
  OurStoryCTADesktop,
  OurStoryCTAMobile,
  OurStoryWrapper
} from './elements';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  // gap should be spacing(6), but GridItems have built-in padding
  // of spacing(1) or spacing(1.5). That may change in the future,
  // in which case these should both be set to 6.
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('sm')} {
    gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5)}px;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4.5)}px;
  }
`;

const OurStoryComponent = ({
  data,
  imageOrientation,
  showTitle = true
}: {
  data: Record<string, any>;
  imageOrientation: 'left' | 'right' | undefined;
  showTitle: boolean;
}) => {
  const imageOptions = { placeholder: 'blurred', style: { width: '100%' } };
  const { headingObj, taglineObj, bodyObj, callToActionObj, mainImageObj } = data;
  const title = headingObj?.heading;
  const tagline = taglineObj?.tagline;
  const body = bodyObj?.body;
  return (
    <OurStoryBlock
      title={showTitle && title}
      image={serializeToImageObject(mainImageObj, imageOptions)}
      imageOrientation={imageOrientation}
    >
      {(tagline || title) && (
        <OurStoryBlockHeadline>{showTitle ? tagline : title}</OurStoryBlockHeadline>
      )}
      {body && (
        <OurStoryBlockText>
          <PortableTextComponent blocks={body} />
        </OurStoryBlockText>
      )}
      {callToActionObj?.linkText && (
        <OurStoryCTADesktop text={callToActionObj.linkText} link={callToActionObj?.url || '#'} />
      )}
    </OurStoryBlock>
  );
};

export const OurStory = ({ data }: { data: Record<string, any>[] }) => {
  const content = formatStoryData(data);
  const { ourStory1, ourStory2, ourStory3 } = content;
  const { callToActionObj } = ourStory3;
  return (
    <OurStoryWrapper>
      <OwnUpGridWrapper>
        <ContentWrapper>
          {ourStory1 && (
            <OurStoryComponent data={ourStory1} imageOrientation="right" showTitle={true} />
          )}
          {ourStory2 && (
            <OurStoryComponent data={ourStory2} imageOrientation="left" showTitle={false} />
          )}
          {ourStory3 && (
            <OurStoryComponent data={ourStory3} imageOrientation="right" showTitle={false} />
          )}
        </ContentWrapper>
        {callToActionObj?.linkText && (
          <OurStoryCTAMobile text={callToActionObj.linkText} link={callToActionObj?.url || '#'} />
        )}
      </OwnUpGridWrapper>
    </OurStoryWrapper>
  );
};
