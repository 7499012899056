import { OwnUpGridContainer, PropsWithTheme } from '@rategravity/own-up-component-library';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { ContentHalf, DesktopTitle, ImageGridItem, MobileTitle, TextGridItem } from './elements';

export type ImageWithTextProps = PropsWithChildren<{
  title?: string | JSX.Element;
  image: JSX.Element;
  imageOrientation?: 'left' | 'right';
}>;

const ContentContainer = styled(OwnUpGridContainer)<{
  // `$` prefix prevents styled from passing props to the DOM
  $imageOrientation: ImageWithTextProps['imageOrientation'];
}>`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('sm')} {
    flex-direction: ${(props) =>
      props.$imageOrientation === 'left' ? 'column' : 'column-reverse'};
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    align-items: center;
  }
`;

export const ImageWithTextHeadlineWrapper = styled.div`
  // Padding should be spacing(4), but it is adjacent to the border
  // between two GridItems, each side of which has padding of spacing(1).
  // That may change in the future, in which case this should be set to 4.
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('sm')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
    text-align: center;
  }
`;

export const ImageWithText = ({
  title,
  image,
  imageOrientation = 'right',
  children: text
}: ImageWithTextProps) => {
  const mobileTitle = title ? <MobileTitle>{title}</MobileTitle> : null;
  const desktopTitle = title ? <DesktopTitle>{title}</DesktopTitle> : null;

  const textContent = (
    <TextGridItem {...{ imageOrientation }}>
      {desktopTitle}
      {text}
    </TextGridItem>
  );
  const imageContent = <ImageGridItem>{image}</ImageGridItem>;
  const rightContent = imageOrientation === 'right' ? imageContent : textContent;
  const leftContent = imageOrientation === 'right' ? textContent : imageContent;

  return (
    // Don't use Fragment here, or any `gap` set by parent will be
    // inherited and add space between the title and content
    <div>
      {mobileTitle}
      <ContentContainer $imageOrientation={imageOrientation} variant="legacy">
        <ContentHalf>{leftContent}</ContentHalf>
        <ContentHalf>{rightContent}</ContentHalf>
      </ContentContainer>
    </div>
  );
};
