import { GuidesSection } from '@rategravity/marketing-components';
import { graphql } from 'gatsby';
import React from 'react';
import {
  AboutSection1,
  AboutSection2,
  AboutSection3,
  AboutSection4,
  AboutSection5
} from '../components/about';
import { Layout } from '../components/layout';
import { getPageSections } from '../modules/handle-sanity-data';
import { QueryData } from '../modules/sanity-data-types';

export const pageQuery = graphql`
  query {
    pages: allSanityPage(filter: { title: { eq: "About" } }) {
      nodes {
        pageBuilder {
          name
          _rawPageBuilder(resolveReferences: { maxDepth: 10 })
        }
      }
    }
  }
`;

export interface PageProps {
  data: QueryData;
}

const SectionComponents = [
  AboutSection1,
  AboutSection2,
  AboutSection3,
  AboutSection4,
  AboutSection5
];

export const About = ({ data }: PageProps) => {
  const pageSections: Record<string, any> = getPageSections(data);

  return (
    <Layout>
      {SectionComponents.map((Section, index) => {
        return (
          pageSections[`section${index + 1}`] && (
            <Section key={`section-${index}`} data={pageSections[`section${index + 1}`]} />
          )
        );
      })}
      <GuidesSection />
    </Layout>
  );
};

export default About;
